import { useQuery } from "@apollo/client";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box, makeStyles } from "@material-ui/core";
import Editor from "ckeditor5-custom-build";
import { Dialog, translate } from "components";
import moment from "moment";
import { GET_NOTICES } from "queries/notification";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CCButton, CCIconButton, CCTypography } from "styles/components";
import { CalendarLeftIcon, CalendarRightIcon, NoticeIcon } from "theme/icons";
import { DEFAULT_DATE_FORMAT } from "types/constants";
import { DIALOG_SIZES, NOTICE_TYPES } from "types/notice";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper": {
      width: props => props.width
    }
  },
  content: {
    padding: 20
  },
  noticeList: {
    minHeight: 240
  },
  noticeItem: {
    minHeight: 40,
    display: "flex",
    gap: 16,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.hover
    }
  },
  noticePagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 4
  },
  noData: {
    height: 280,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
}));

const LIMIT_PAGE = 6;

const DialogNotificationPreview = ({ open, onClose }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedNotice, setSelectedNotice] = useState();

  const { data, loading } = useQuery(GET_NOTICES, {
    variables: {
      input: {
        limit: LIMIT_PAGE,
        isPublished: true,
        page: currentPage,
        type: NOTICE_TYPES.NOTICE
      }
    }
  });
  const notices = data?.notices?.items || [];

  const totalPage = data?.notices?.total
    ? Math.ceil(data?.notices?.total / LIMIT_PAGE)
    : 0;

  const handlePrevPage = () => {
    if (currentPage === 1) {
      return;
    }

    setCurrentPage(prev => prev - 1);
  };

  const handleNextPage = () => {
    if (currentPage === totalPage) {
      return;
    }

    setCurrentPage(prev => prev + 1);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title={translate(intl, "noticeTab.notice")}
        contents={
          <Box className={classes.content}>
            <Box
              aria-label="notice-label"
              display="flex"
              alignItems="center"
              gridGap="4px"
              mb="20px"
            >
              <NoticeIcon style={{ color: "#6B7C8A" }} />
              <CCTypography variant="h5">
                {translate(intl, "noticeTab.notice")}
              </CCTypography>
            </Box>

            {!loading && notices.length > 0 && (
              <>
                <Box aria-label="notice-list" className={classes.noticeList}>
                  {notices.map(itm => (
                    <Box
                      aria-label="notice-item"
                      className={classes.noticeItem}
                      onClick={() => setSelectedNotice(itm)}
                    >
                      <CCTypography
                        variant="h6"
                        style={{ wordBreak: "break-all" }}
                      >
                        {itm.title}
                      </CCTypography>
                      <CCTypography variant="body1" color="textSecondary">
                        {moment.unix(itm.createdAt).format(DEFAULT_DATE_FORMAT)}
                      </CCTypography>
                    </Box>
                  ))}
                </Box>
                <Box
                  aria-label="notice-pagination"
                  className={classes.noticePagination}
                >
                  <CCIconButton
                    size="small"
                    disabled={currentPage === 1}
                    onClick={handlePrevPage}
                  >
                    <CalendarLeftIcon
                      style={{
                        color: "#455A64",
                        opacity: currentPage === 1 ? 0.3 : 1
                      }}
                    />
                  </CCIconButton>
                  <Box display="flex" alignItems="center" gridGap="2px">
                    <CCTypography
                      variant="body1"
                      style={{ fontWeight: "600", color: "#546e7a" }}
                    >
                      {currentPage}
                    </CCTypography>
                    /
                    <CCTypography variant="body1" style={{ color: "#546e7a" }}>
                      {totalPage}
                    </CCTypography>
                  </Box>
                  <CCIconButton
                    size="small"
                    disabled={currentPage === totalPage}
                    onClick={handleNextPage}
                  >
                    <CalendarRightIcon
                      style={{
                        color: "#455A64",
                        opacity: currentPage === totalPage ? 0.3 : 1
                      }}
                    />
                  </CCIconButton>
                </Box>
              </>
            )}
            {!loading && notices.length === 0 && (
              <Box className={classes.noData}>
                <img src="/images/noData/Notoce_none.png" alt="noData" />
                <CCTypography
                  variant="body1"
                  display="block"
                  style={{ color: "#b0bec5", fontWeight: "bold" }}
                >
                  {translate(intl, "noNotice")}
                </CCTypography>
              </Box>
            )}
            {loading && <Box className={classes.noData}></Box>}
          </Box>
        }
        contentsPadding={false}
        maxWidth={"md"}
        fullWidth
        endActions={
          <>
            <CCButton color="sub" onClick={onClose}>
              {translate(intl, "button.close")}
            </CCButton>
          </>
        }
      />
      {selectedNotice && (
        <DialogNoticeDetail
          data={selectedNotice}
          open={Boolean(selectedNotice)}
          onClose={() => setSelectedNotice(null)}
        />
      )}
    </>
  );
};

export default DialogNotificationPreview;

const DialogNoticeDetail = ({ data, open, onClose }) => {
  const widthDialog =
    Object.values(DIALOG_SIZES).find(itm => itm.value === data?.size).width ??
    DIALOG_SIZES.SMALL.width;

  const classes = useStyles({ width: widthDialog });
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={translate(intl, "noticeTab.notice")}
      contents={
        <Box className={classes.content}>
          <CCTypography variant="h3" align="center">
            {data.title}
          </CCTypography>
          <div
            className="ck-content ck-content-readonly"
            style={{ minHeight: 500 }}
            dangerouslySetInnerHTML={{ __html: data.content }}
          ></div>
        </Box>
      }
      contentsPadding={false}
      maxWidth={false}
      classes={{ root: classes.root }}
      fullWidth
      endActions={
        <>
          <CCButton color="sub" onClick={onClose}>
            {translate(intl, "button.close")}
          </CCButton>
        </>
      }
    />
  );
};
