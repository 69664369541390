import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  capitalize
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
  CVMenu,
  CVTable,
  DialogWarningConfirm,
  Modal,
  TimerWithRefetch,
  translate
} from "components";
import { enqueueToast } from "components/Toast";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useReducer, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogContent,
  CCDialogInputError,
  CCDialogSimple,
  CCDialogTitle,
  CCTypography
} from "styles/components";
import { CheckIcon, RefreshIcon } from "styles/icons";
import { StatisticBox } from "../ApplicationManagement/components";

import { MoreVert } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import {
  GET_LAB_APPLICATION_REQ_LIST,
  GET_LAB_APPLICATION_REQ_STATS,
  REGISTER_NEW_LAB,
  UPDATE_ACCOUNT_TYPE_LAB_REQUEST,
  UPDATE_LAB_APPLICATION_REQ
} from "queries/application";
import {
  ApplicationForm,
  RegistrationDetail,
  RegistrationFilter
} from "./components";

import { DEFAULT_DATE_TIME_FORMAT, LIMIT_PAGINATION } from "types/constants";
import {
  ACCOUNT_TYPE_VALUES,
  BASE_REGISTRATION_STATUS
} from "types/registrationManage";

import RegistrationDownload from "./components/RegistrationDownload/RegistrationDownload";
import { isExpiredWithdrawTime, makeMenuItemList } from "./helper";

const LIMIT = LIMIT_PAGINATION;
const INITIAL_FILTER = {
  // start: moment().startOf("month").unix(),
  // end: moment().endOf("month").unix(),
  limit: LIMIT,
  page: 1,
  searchText: "",
  status: "9",
  accountType: "lab"
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.common.white
  },
  grid__container: { flexDirection: "column", display: "flex", height: "100%" },
  timer: {
    position: "absolute",
    zIndex: 1200,
    top: 18,
    right: 32,
    display: "flex"
  },
  timer__divider: {
    height: 36,
    borderLeft: "3px solid rgba(0, 0, 0, 0.16)",
    margin: "0 16px"
  },
  paper: { height: "100%", width: "100%" },
  content: { height: "calc(100% - 55px)", overflow: "hidden" },
  container: { height: "100%" },
  container__filter: {
    width: "100%",
    padding: "4px 12px",
    margin: "0px",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  item__table: {
    // "margin-left": "-7%",
    marginBottom: theme.spacing(1),
    // backgroundColor: "red"
    height: "54vh"
  },
  table: {
    height: "100%",
    textAlign: "center",

    "& .MuiTypography-colorInherit": {
      color: theme.palette.input.main
    }
  },
  table__body: {
    backgroundColor: "rgba(207, 216, 220, 0.87)",
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    }
  },
  table__cell: {
    padding: "6px 4px"
  },
  toolbar__divider: {
    borderLeft: `1px solid ${theme.palette.border.main}`,
    height: 36,
    marginRight: 8
  },
  ml8: {
    marginLeft: 8
  },
  container__status: {
    position: "relative",
    display: "flex",
    borderTop: `1px solid ${theme.palette.border.main}`,
    padding: "28px 12px"
  },
  color__red: {
    color: "red"
  },
  color__green: {
    color: "green"
  },
  lab__search: {
    position: "absolute",
    right: 16,
    bottom: 16,
    "background-color": "#00897b"
    // color: "white"
  },
  refresh__icon: {
    // marginRight: "3%",
    cursor: "pointer",
    color: "blue"
  },
  createLab_dialog: {
    marginTop: "-60px",
    minWidth: "999px"
  },
  detailLab__dialog: {
    minWidth: "2000px"
  },
  waiting: {
    color: "blue"
  },
  approved: {
    color: "green"
  },
  refused: {
    color: "red"
  },
  withdraw: {
    color: "gray"
  },
  statusDate__text: {
    color: "black"
  },
  footerDetailDialog: {
    "&.MuiBox-root": {
      padding: 0,
      borderTop: "none"
    }
  }
}));

const initializer = {
  filter: INITIAL_FILTER,
  checkedList: {},
  lab: {},
  isOpenWithDrawConfirmDialog: false,
  selectedId: null,
  isOpenRegistrationErr: false,
  isOpenProcessingTerminated: false,
  isOpenConfirmTerminated: false
};

const reducer = (state, action) => {
  // console.log("action.type", action);
  switch (action.type) {
    case "detail":
      return {
        ...state,
        lab: action.target
      };
    case "update":
      return {
        ...state,
        lab: action.target
      };
    case "withDrawDialogState":
      return {
        ...state,
        isOpenWithDrawConfirmDialog: action.target
      };
    case "selectedId":
      return {
        ...state,
        selectedId: action.target
      };
    case "filter":
      const newState = {
        ...state,
        filter: action.target
      };
      return newState;
    case "setCheckedList":
      return {
        ...state,
        checkedList: action.target
      };
    case "confirmRegistrationErr": {
      return {
        ...state,
        isOpenRegistrationErr: action.target
      };
    }
    case "processingTerminatedState": {
      return {
        ...state,
        isOpenProcessingTerminated: action.target
      };
    }
    case "completeTerminatedState": {
      return {
        ...state,
        isOpenConfirmTerminated: action.target
      };
    }
    case "reset":
      return { ...initializer };
    default:
      break;
  }

  return state;
};

const RegistrationManage = () => {
  const [state, dispatchState] = useReducer(reducer, initializer);
  const formRef = useRef();
  const [registrationStatus, setRegistrationStatus] = useState();
  const {
    filter,
    checkedList,
    lab,
    isOpenWithDrawConfirmDialog,
    selectedId,
    isOpenRegistrationErr,
    isOpenProcessingTerminated,
    isOpenConfirmTerminated,
    isConfirmTerminated
  } = state;

  // const [isOpenProcessingTerminated, setIsOpenProcessingTerminated] = useState(false)

  const {
    WAITING,
    APPROVED,
    REFUSED,
    WITHDRAW,
    CANCEL_TERMINATED,
    COMPLETE_TERMINATED,
    PROCESSING_TERMINATED
  } = BASE_REGISTRATION_STATUS;

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);

  const [inputErr, setInputErr] = useState(false);
  const intl = useIntl();
  const [registerNewLab] = useMutation(REGISTER_NEW_LAB);

  const { data: dataStatistics, refetch: refetchStatistics } = useQuery(
    GET_LAB_APPLICATION_REQ_STATS
  );

  const openProcessingTerminated = () => {
    dispatchState({
      type: "processingTerminatedState",
      target: true
    });
  };

  const handleConfirmRegistrationErr = err => {
    dispatchState({
      type: "confirmRegistrationErr",
      target: false
    });
  };

  const {
    data,
    loading,
    error: errr1,
    refetch: refetchApplicationReqList
  } = useQuery(GET_LAB_APPLICATION_REQ_LIST, {
    variables: {
      input: INITIAL_FILTER
    }
  });

  /**
   * @param {String} newStatus
   * @returns
   */
  const createToastMessage = newStatus => {
    newStatus = newStatus.toString();
    let message = "";
    const { currentStatus } = lab;

    if (newStatus === WAITING) {
      message = "Registration has been waiting.​";
    }

    if (newStatus === APPROVED) {
      message = "Registration has been approved.​";
    }

    if (newStatus === REFUSED) {
      message = "Registration has been refused";
    }

    if (newStatus === PROCESSING_TERMINATED) {
      message = "Processing Termination has been applied.";
    }

    if (newStatus === CANCEL_TERMINATED) {
      message = "Termination has been canceled.";
    }

    if (newStatus === COMPLETE_TERMINATED) {
      message = "Termination has been completed.​";
    }

    console.log("message", message);

    return message;
  };

  const [updateLabApplicationRequest] = useMutation(
    UPDATE_LAB_APPLICATION_REQ,
    {
      onCompleted: () => {
        refetchStatistics();
        refetchApplicationReqList();
        // const changeStatusMessage = createToastMessage();
        // openToast(changeStatusMessage);
        // console.log("lab", lab);
      }
    }
  );

  const [updateLabRequestAccountType] = useMutation(
    UPDATE_ACCOUNT_TYPE_LAB_REQUEST,
    {
      onCompleted: () => {
        refetchStatistics();
        refetchApplicationReqList();
      }
    }
  );

  const [valueForm, setValueForm] = useState({});
  const [isOpenRegistrationDialog, setIsOpenRegistrationDialog] =
    useState(false);

  const [isOpenDetailRegistrationDialog, setIsOpenDetailDialog] =
    useState(false);

  const totalPages = Math.ceil(data?.labApplicationRequestList?.total / LIMIT);

  const handleOnChangePagination = (event, value) => {
    dispatchState({
      type: "filter",
      target: { ...filter, page: value }
    });

    refetchApplicationReqList({
      input: {
        ...filter,
        accountType:
          filter.accountType === ACCOUNT_TYPE_VALUES.ALL.value
            ? undefined
            : filter.accountType,
        page: value
      }
    });
  };

  let error = useRef({
    labId: true,
    userId: true,
    birthDate: true,
    cellPhoneNumber: true,
    tellPhoneNumber: true,
    password: false,
    address: true,
    managerName: true,
    email: true,
    labName: true,
    confirmPassword: false
  });

  const onChange = (key, value) => {
    setValueForm({ ...valueForm, [key]: value });
  };

  const handleClickMoreVert = (event, rowData) => {
    let { status, withdrawAt } = rowData;

    status = String(status);

    const disableMoreVertList = [
      REFUSED,
      APPROVED,
      COMPLETE_TERMINATED,
      PROCESSING_TERMINATED
    ];
    if (status === undefined) return;

    if (disableMoreVertList.includes(status)) {
      return;
    }

    if (status === WITHDRAW && !isExpiredWithdrawTime(withdrawAt)) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const onError = (key, value) => {
    error.current = { ...error.current, [key]: value };
  };

  const closeWithDrawConfirmDialog = () => {
    dispatchState({
      type: "withDrawDialogState",
      target: false
    });
  };

  const openWithDrawConfirmDialog = () => {
    dispatchState({
      type: "withDrawDialogState",
      target: true
    });
    // setAnchorEl(null);
  };

  const handleWithDrawLab = e => {
    updateLabApplicationRequest({
      variables: {
        input: {
          status: Number(WITHDRAW),
          ids: [selectedId]
        }
      }
    }).then(() => {
      // refetchDataWithCurrentState();
    });

    dispatchState({
      type: "withDrawDialogState",
      target: false
    });
    // refetchData();
  };

  const handleCloseDetailDialog = () => {
    setIsOpenDetailDialog(false);
  };

  const registrationStatistics = [
    {
      title: "Total",
      color: "black",
      data:
        dataStatistics && dataStatistics.labApplicationRequestStatistics.total
    },
    {
      title: "Waiting",
      color: "blue",
      data:
        dataStatistics &&
        dataStatistics.labApplicationRequestStatistics.totalNewApplication
    },
    {
      title: "Approved",
      color: "green",
      data:
        dataStatistics &&
        dataStatistics.labApplicationRequestStatistics.totalAcceptedApplication
    },
    {
      title: "Refused",
      color: "red",
      data:
        dataStatistics &&
        dataStatistics.labApplicationRequestStatistics.totalRefusedApplication
    },
    {
      title: "Processing Termination",
      color: "gray",
      data:
        (dataStatistics &&
          dataStatistics.labApplicationRequestStatistics
            .totalProcessingTerminationApplication) ||
        0
    },
    {
      title: "Termination Complete",
      color: "black",
      data:
        (dataStatistics &&
          dataStatistics.labApplicationRequestStatistics
            .totalCompleteTerminationApplication) ||
        0
    }
  ];

  const changeStatus = e => {
    e.stopPropagation();

    let newStatus = e.target.value;
    newStatus = String(newStatus);

    // console.log("selectedId", selectedId)

    if (newStatus === WITHDRAW) {
      openWithDrawConfirmDialog();
    }

    if (newStatus !== WITHDRAW) {
      updateLabApplicationRequest({
        variables: {
          input: {
            status: Number(newStatus),
            ids: [selectedId]
          }
        }
      }).then(() => {
        displayToastMessage(newStatus);
        // refetchDataWithCurrentState();
        // refetchData();
      });
    }
    return;
  };

  const reformatStatus = (status = "") => {
    if (status === COMPLETE_TERMINATED) {
      return "Complete Termination​";
    }

    if (status === WAITING) {
      return "Waiting";
    }

    if (status === APPROVED) {
      return "Approved";
    }

    if (status === REFUSED) {
      return "Refused";
    }

    if (status === WITHDRAW) {
      return "Withdraw";
    }

    if (status === PROCESSING_TERMINATED) {
      return "Processing Terminated";
    }
  };

  /**
   * Get format date string from status
   * @param {Object} param0
   * @returns String
   */
  const getDateTimeBaseStatus = ({
    status,
    createdAt,
    acceptedAt,
    refusedAt,
    withdrawAt,
    terminatedAt,
    processingTerminatedAt,
    registrationAt,
    updatedAt
  }) => {
    if (status === WAITING) {
      // return moment.unix(createdAt).format(DEFAULT_DATE_TIME_FORMAT);
      return moment.unix(createdAt).format(DEFAULT_DATE_TIME_FORMAT);
    }

    if (status === APPROVED) {
      return moment.unix(acceptedAt).format(DEFAULT_DATE_TIME_FORMAT);
    }

    if (status === REFUSED) {
      return moment.unix(refusedAt).format(DEFAULT_DATE_TIME_FORMAT);
    }

    if (status === WITHDRAW) {
      return moment.unix(withdrawAt).format(DEFAULT_DATE_TIME_FORMAT);
    }

    if (status === COMPLETE_TERMINATED) {
      return moment.unix(terminatedAt).format(DEFAULT_DATE_TIME_FORMAT);
    }

    if (status === PROCESSING_TERMINATED) {
      return moment
        .unix(processingTerminatedAt)
        .format(DEFAULT_DATE_TIME_FORMAT);
    }
  };

  /**
   * When click drop down change status would be display
   * @returns
   */
  const reformatMoreVert = () => {
    const { status, withdrawAt } = lab;

    const menuStatusItemList = Boolean(anchorEl) && (
      <CVMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        // menuItems={lab.status === 1 ? menuItemList : menuItemList2}
        menuItems={makeMenuItemList(lab.status, withdrawAt, changeStatus) || []}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        elevation={1}
      />
    );
    return menuStatusItemList;
  };

  /**
   * Reset error form registration to default
   */

  const resetFormRegistration = () => {
    error.current = {
      labId: true,
      userId: true,
      birthDate: true,
      cellPhoneNumber: true,
      tellPhoneNumber: true,
      password: false,
      address: true,
      managerName: true,
      email: true,
      labName: true,
      confirmPassword: false
    };
  };

  const heads = [
    { key: "labId", label: "Lab ID", className: classes.table__cell },
    {
      key: "labName",
      label: "Lab Name",
      className: classes.table__cell
    },
    {
      key: "labTel",
      label: "Tel No.",
      className: classes.table__cell
    },
    {
      key: "accountType",
      label: translate(intl, "common.ui.accountType"),
      className: classes.table__cell,
      component: ({ cellData }) => {
        return capitalize(cellData);
      }
    },
    {
      key: "createdAt",
      label: "Registration Date",
      className: classes.table__cell,
      component: ({ cellData }) => {
        if (cellData) {
          return (
            <CCTypography variant="body1">
              {moment.unix(cellData).format(DEFAULT_DATE_TIME_FORMAT)}
            </CCTypography>
          );
        }
        return "-";
        // return moment().format(DEFAULT_DATE_TIME_FORMAT)
      }
    },
    {
      key: "status",
      label: "Register Status",
      className: classes.table__cell,
      component: ({ rowData, cellData }) => {
        const {
          createdAt,
          acceptedAt,
          refusedAt,
          withdrawAt,
          terminatedAt,
          processingTerminatedAt,
          completeTerminatedAt,
          registrationAt,
          statusUpdatedAt,
          updatedAt
        } = rowData;

        return (
          <>
            <CCTypography
              variant="body1"
              className={clsx({
                [classes.waiting]: WAITING === String(cellData),
                [classes.approved]: APPROVED === String(cellData),
                [classes.refused]: REFUSED === String(cellData),
                [classes.withdraw]: [
                  WITHDRAW,
                  PROCESSING_TERMINATED,
                  COMPLETE_TERMINATED
                ].includes(String(cellData))
              })}
            >
              {<span> {reformatStatus(String(cellData))} </span>}{" "}
              <span className={classes.statusDate__text}>
                ({moment.unix(statusUpdatedAt).format(DEFAULT_DATE_TIME_FORMAT)}
                )
                {/* {getDateTimeBaseStatus({
                  status: String(cellData),
                  createdAt,
                  acceptedAt: createdAt,
                  refusedAt: createdAt,
                  withdrawAt: createdAt,
                  terminatedAt: createdAt,
                  processingTerminatedAt: createdAt,
                  completeTerminatedAt: createdAt,
                  registrationAt: createdAt
                })} */}
              </span>
            </CCTypography>
          </>
        );
      }
    },

    {
      key: "moreVert",
      width: 100,
      className: classes.table__cell,
      component: ({ rowData }) => {
        return (
          <Grid>
            <MoreVert onClick={event => handleClickMoreVert(event, rowData)} />
            {reformatMoreVert()}
          </Grid>
        );
      }
    }
  ];

  const handleOnSearch = value => {
    dispatchState({
      type: "filter",
      target: { ...value, page: 1 }
    });
    refetchApplicationReqList({
      input: {
        limit: LIMIT,
        searchText: value.searchText,
        start: value.start,
        end: value.end,
        status: value.status,
        accountType:
          value.accountType === ACCOUNT_TYPE_VALUES.ALL.value
            ? undefined
            : value.accountType
      }
    });
  };

  const refetchData = () => {
    dispatchState({
      type: "reset"
    });
    refetchStatistics();
    refetchApplicationReqList({
      input: INITIAL_FILTER
    });
  };

  const refetchDataWithCurrentState = () => {
    refetchStatistics();
    refetchApplicationReqList({
      input: filter
    });
    // console.log("refetchDataWithCurrentState.filter", filter)
  };

  const openRegistrationDialog = () => {
    setIsOpenRegistrationDialog(true);
  };

  const onCloseRegistrationDialog = () => {
    setValueForm({});
    resetFormRegistration();
    setIsOpenRegistrationDialog(false);
  };

  const openDetailLabDialog = () => {
    setIsOpenDetailDialog(true);
  };

  const closeDetailLabDialog = () => {
    setIsOpenDetailDialog(false);
  };

  const openConfirmTerminatedDialog = () => {
    dispatchState({
      type: "completeTerminatedState",
      target: true
    });
  };

  const closeConfirmTerminatedDialog = () => {
    dispatchState({
      type: "completeTerminatedState",
      target: false
    });
  };

  /**
   * @param {String} newStatus
   */
  const displayToastMessage = newStatus => {
    const changeStatusMessage = createToastMessage(newStatus);
    openToast(changeStatusMessage);
  };

  // const handleCompleteTerminated = lab => {
  //   const { id, status: newStatus } = lab;
  //   agreeConfirmTerminated();

  //   isConfirmTerminated
  //   updateLabApplicationRequest({
  //     variables: {
  //       input: {
  //         status: Number(newStatus),
  //         ids: [id]
  //       }
  //     }
  //   }).then(() => {
  //     displayToastMessage(newStatus);
  //   });

  //   handleCloseDetailDialog();
  //   closeConfirmTerminatedDialog();
  // };

  // const agreeConfirmTerminated = () => {
  //   dispatchState({
  //     type: "confirmTerminated",
  //     target: true
  //   });
  // };

  const disagreeConfirmTerminated = () => {
    dispatchState({
      type: "update",
      target: { ...lab, status: PROCESSING_TERMINATED }
    });
  };

  const handleSave = lab => {
    const { id, status: newStatus } = lab;

    if (newStatus === COMPLETE_TERMINATED) {
      // dispatchState({
      //   type: "completeTerminatedState",
      //   target: true
      // });

      updateLabApplicationRequest({
        variables: {
          input: {
            status: Number(newStatus),
            ids: [id]
          }
        }
      }).then(() => {
        displayToastMessage(newStatus);
      });
      handleCloseDetailDialog();
      closeConfirmTerminatedDialog();

      return;
    }

    updateLabApplicationRequest({
      variables: {
        input: {
          status: Number(newStatus),
          ids: [id]
        }
      }
    }).then(() => {
      displayToastMessage(newStatus);
    });

    // if(status === COMPLETE_TERMINATED) {
    //   console.log("open COMPLETE_TERMINATED")
    //   dispatchState({
    //     type: "completeTerminatedState",
    //     target: true
    //   });
    // }

    if (newStatus === PROCESSING_TERMINATED) {
      dispatchState({
        type: "processingTerminatedState",
        target: false
      });
    }

    closeDetailLabDialog();
  };

  const updateStatusEventEmits = lab => {
    dispatchState({
      type: "update",
      target: lab
    });

    if (lab) {
      const { status, currentStatus } = lab;

      // If status = PROCESSING_TERMINATED open dialog to confirm
      /**
       * Check currentStatus === PROCESSING_TERMINATED because do not want so dialog again
       */
      if (
        status === PROCESSING_TERMINATED &&
        currentStatus !== PROCESSING_TERMINATED
      ) {
        console.log("openProcessingTerminated");
        openProcessingTerminated();
      }

      /**
       * Open dialog when status want to change is COMPLETE_TERMINATED
       */
      if (status === COMPLETE_TERMINATED) {
        openConfirmTerminatedDialog();
      }
    }
  };

  const openToast = (message = "") => {
    enqueueToast(enqueueSnackbar, message);
  };

  const handleChangeAccountType = labData => {
    updateLabRequestAccountType({
      variables: {
        input: {
          status: labData.status,
          ids: [labData.id],
          accountType: labData.accountType
        }
      }
    }).then(() => {
      openToast(
        translate(intl, "accountType.msg.switchedTo", {
          targetAccountType: capitalize(labData.accountType)
        })
      );
    });
  };

  const handleNext = () => {
    formRef.current.submit();
    // if (validateSignUp(error.current)) {
    //   // console.log("validateSignUp", validateSignUp(error.current));

    //   setInputErr(validateSignUp(error.current));
    //   return;
    // } else {
    //   registerNewLab({
    //     variables: {
    //       input: {
    //         labId: valueForm.labId,
    //         labName: valueForm.labName,
    //         password: valueForm.password,
    //         labTelNumber: valueForm.tellPhoneNumber,
    //         email: valueForm.email,
    //         address: valueForm.address,
    //         birthDate: valueForm.birthDate || moment().unix(),
    //         managerName: valueForm.managerName,
    //         managerCellNumber: valueForm.cellPhoneNumber,
    //         staffId: valueForm.userId,
    //         erc: valueForm.erc
    //       }
    //     }
    //   })
    //     .then(res => {
    //       setValueForm({});
    //       refetchData();
    //       refetchApplicationReqList({
    //         input: {
    //           limit: LIMIT
    //         }
    //       });
    //       openToast("Application form is saved");
    //       resetFormRegistration();
    //       onCloseRegistrationDialog();
    //     })
    //     .catch(err => {
    //       const { graphQLErrors, networkError } = err;

    //       if (
    //         graphQLErrors &&
    //         graphQLErrors[0]?.extensions?.code === "LABEXIST"
    //       ) {
    //         dispatchState({
    //           type: "confirmRegistrationErr",
    //           target: true
    //         });
    //       }
    //       // console.log("graphQLErrors", graphQLErrors[0].extensions.code);
    //       // setStatusRegister("networkError");
    //       // if (graphQLErrors) {
    //       //   graphQLErrors.forEach(graphQLError => {
    //       //     setStatusRegister("registrationFailed");
    //       //   });
    //       // }
    //       // if (networkError) {
    //       //   setStatusRegister("networkError");
    //       // }
    //       // setValueForm({});
    //       // onCloseRegistrationDialog();
    //       // refetchData();
    //     });
    // }
  };

  if (errr1) return <div>Some thing went wrong!</div>;

  if (loading) return <LinearProgress color="secondary" />;

  return (
    <>
      <Box className={classes.timer}>
        <TimerWithRefetch refetch={refetchData} />
        <div className={classes.timer__divider}></div>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <CCDialogTitle>Registration Manage</CCDialogTitle>
          <CCDialogContent className={classes.content} noPadding>
            <Grid
              container
              direction={"column"}
              wrap={"nowrap"}
              className={classes.container}
            >
              <Grid item className={classes.container__status}>
                <StatisticBox statistics={registrationStatistics} />

                <CCButton
                  variant="contained"
                  // color="normal"
                  // startIcon={<CommitmentIcon />}
                  className={classes.lab__search}
                  onClick={
                    () => openRegistrationDialog()
                    // dispatchState({
                    //   type: "setLabSearchModal",
                    //   target: true
                    // })
                  }
                >
                  Registration
                </CCButton>
              </Grid>

              <Grid
                className={classes.container__filter}
                container
                justify="space-between"
              >
                <Grid>
                  <Grid container justify="flex-start" alignItems="center">
                    <RegistrationFilter
                      filter={filter}
                      onSearch={handleOnSearch}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container>
                    <RegistrationDownload
                      filename="Registration Manage"
                      filter={{
                        ...filter,
                        page: 1,
                        accountType:
                          filter.accountType === ACCOUNT_TYPE_VALUES.ALL.value
                            ? undefined
                            : filter.accountType,
                        limit: data?.labApplicationRequestList?.total || 0
                      }}
                    />
                    <Button
                      className={classes.refresh__icon}
                      onClick={refetchData}
                    >
                      <RefreshIcon fontSize="large" color="secondary" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Box className={classes.item__table}>
                <CVTable
                  onRowClick={({ e, data, rowIndex, key }) => {
                    dispatchState({
                      type: "detail",
                      target: { ...data, currentStatus: String(data.status) }
                    });

                    dispatchState({
                      type: "selectedId",
                      target: data.id
                    });

                    if (key !== "moreVert") {
                      openDetailLabDialog(data);
                    }
                  }}
                  heads={heads}
                  contents={
                    data?.labApplicationRequestList &&
                    data?.labApplicationRequestList?.items
                  }
                  classes={{ table__body: classes.table__body }}
                  className={`${classes.table} scrollbar`}
                />
              </Box>

              <Grid container item justifyContent="center" direction="column">
                <Pagination
                  count={totalPages}
                  page={filter.page}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  onChange={handleOnChangePagination}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
          </CCDialogContent>
        </Box>
      </Box>

      {isOpenWithDrawConfirmDialog && (
        <DialogWarningConfirm
          open={isOpenWithDrawConfirmDialog}
          onAgree={handleWithDrawLab}
          onClose={closeWithDrawConfirmDialog}
          agreeButtonLabel={"Confirm"}
          disagreeButtonLabel={"Cancel"}
          title={"Are you sure to withdraw this lab?"}
          subTitle={"Withdrawn data could not be recovered"}
        />
      )}

      <Modal
        open={isOpenRegistrationDialog}
        onClose={onCloseRegistrationDialog}
        width={960}
        title={translate(intl, "signUp.ui.stepsForm")}
        body={
          <ApplicationForm
            ref={formRef}
            onSuccess={() => {
              setRegistrationStatus("success");
              refetchData();
              refetchApplicationReqList({
                input: INITIAL_FILTER
              });
              openToast("Application form is saved");
              onCloseRegistrationDialog();
            }}
            onError={error => {
              if (error?.networkError || !error?.graphQLErrors?.length) {
                setRegistrationStatus("network_error");
              }

              if (error?.graphQLErrors?.length) {
                setRegistrationStatus("failed");
              }

              const { graphQLErrors, networkError } = error;
              if (
                graphQLErrors &&
                graphQLErrors[0]?.extensions?.code === "LABEXIST"
              ) {
                dispatchState({
                  type: "confirmRegistrationErr",
                  target: true
                });
              }
            }}
          />
        }
        footer={
          <Box display="flex" justifyContent="end">
            <CCButton onClick={onCloseRegistrationDialog}>Cancel</CCButton>
            <CCButton
              onClick={() => handleNext(valueForm)}
              startIcon={<CheckIcon />}
              variant="contained"
            >
              Save
            </CCButton>
          </Box>
        }
      />

      {isOpenRegistrationErr && (
        <DialogWarningConfirm
          open={isOpenRegistrationErr}
          onAgree={handleConfirmRegistrationErr}
          onClose={handleConfirmRegistrationErr}
          agreeButtonLabel={"Confirm"}
          title={`Please click "Check ID" and check if the ID is duplicate `}
        />
      )}

      <Modal
        open={isOpenDetailRegistrationDialog}
        onClose={handleCloseDetailDialog}
        width={960}
        title={"Details"}
        classes={{
          footer:
            lab.currentStatus == WAITING ||
            lab.currentStatus === PROCESSING_TERMINATED
              ? ""
              : classes.footerDetailDialog
        }}
        body={
          <RegistrationDetail
            data={lab}
            onUpdate={updateStatusEventEmits}
            // onProcessingTerminate={openProcessingTerminated}
            onChangeAccountType={handleChangeAccountType}
          />
        }
        footer={
          <Box display="flex" justifyContent="end">
            {lab.currentStatus == WAITING ||
            lab.currentStatus === PROCESSING_TERMINATED ? (
              <>
                <CCButton onClick={handleCloseDetailDialog}>Cancel</CCButton>
                <CCButton
                  onClick={() => handleSave(lab)}
                  startIcon={<CheckIcon />}
                  variant="contained"
                >
                  Save
                </CCButton>
              </>
            ) : null}
          </Box>
        }
      />

      {/* {isOpenDetailRegistrationDialog && (
        <CCDialogSimple
          className={classes.createLab_dialog}
          title={"Details"}
          open={isOpenDetailRegistrationDialog}
          maxWidth={"md"}
          contents={
            <RegistrationDetail
              data={lab}
              onUpdate={updateStatusEventEmits}
              // onProcessingTerminate={openProcessingTerminated}
              onChangeAccountType={handleChangeAccountType}
            />
          }
          onCloseButton={handleCloseDetailDialog}
          onClose={handleCloseDetailDialog}
          endActions={
            <>
              {lab.currentStatus == WAITING ||
              lab.currentStatus === PROCESSING_TERMINATED ? (
                <>
                  <CCButton onClick={handleCloseDetailDialog}>Cancel</CCButton>

                  <CCButton
                    onClick={() => handleSave(lab)}
                    startIcon={<CheckIcon />}
                    variant="contained"
                  >
                    Save
                  </CCButton>
                </>
              ) : (
                ""
              )}
            </>
          }
        />
      )} */}

      {isOpenProcessingTerminated && (
        <DialogWarningConfirm
          open={isOpenProcessingTerminated}
          onAgree={() => {
            handleSave(lab);

            // dispatchState({
            //   type: "processingTerminatedState",
            //   target: false
            // });
          }}
          onClose={() => {
            dispatchState({
              type: "processingTerminatedState",
              target: false
            });
          }}
          agreeButtonLabel={"Confirm"}
          disagreeButtonLabel={"Cancel"}
          title={`Have you given sufficient advance notice to \n the clinic of service termination ?`}
          subTitle={""}
        />
      )}

      {isOpenConfirmTerminated && (
        <DialogWarningConfirm
          open={isOpenConfirmTerminated}
          // onAgree={() => handleCompleteTerminated(lab)}
          onAgree={() => closeConfirmTerminatedDialog(lab)}
          onClose={() => {
            // dispatchState({
            //   type: "completeTerminatedState",
            //   target: false
            // });
            closeConfirmTerminatedDialog();
            disagreeConfirmTerminated();
            // setIsOpenDetailDialog(false);
          }}
          agreeButtonLabel={"Confirm"}
          disagreeButtonLabel={"Cancel"}
          title={`Are you sure you want to complete the termination`}
          subTitle={`Can't change status after termination`}
        />
      )}

      {Boolean(inputErr) && (
        <DialogWarningConfirm
          open={Boolean(inputErr)}
          onAgree={() => setInputErr(null)}
          agreeButtonLabel={translate(intl, "button.confirm")}
          title={translate(intl, inputErr)}
        />
      )}
    </>
  );
};

export default RegistrationManage;
